import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../layouts'

import people from "../data/people.yaml"

import AboutSidenav from "../partials/about-sidenav"

import '../scss/about.scss'

import image from '../assets/images/about-image-min.jpg'

const AboutPage = ({data}) => {
  return (
    <Layout>
      <div className="about">

        <Helmet>
          <title>DigiWells – About</title>
          <meta property="og:title" content="DigiWells – About" />
          <meta name="description" content="Digital Well Center for Value Creation, Competitiveness and Minimum Environmental Footprint. Get to know us." />
          <meta property="og:description" content="Digital Well Center for Value Creation, Competitiveness and Minimum Environmental Footprint. Get to know us." />
          <meta name="image" content={image} />
          <meta property="og:image" content={image} />
        </Helmet>

        <div className="container">
          <AboutSidenav />

          <div className="about-content">
            <h1 className="page-title">About</h1>

            <div className="page-intro">
              DigiWells: Digital Well Center for Value Creation, Competitiveness and Minimum Environmental Footprint
            </div>

            <div className="page-intro">
              <img alt="" src={image} />
            </div>
            <div className="about-content-text">
              <p>
                Digitalization, new sensors, new high-speed telemetry solutions, automation, autonomy, and improved work processes has the potential to enable a step change of the well delivery process. The centre will explore these possibilities by combining domain knowledge with fundamental research to accelerate the digital transformation of the well delivery process. The centre aims to develop work processes for planning drilling and well operations, new sensors, solutions for interoperability, solutions for automated and autonomous drilling, and decision support systems for geosteering. New solutions will be demonstrated at the national research infrastructures OpenLab Drilling, Ullrigg and against field data from operators.
              </p>
              <p>
                The centre will become a collaborative arena for operators, service industry, public authorities, research institutions and academia in Norway and internationally. Results from the centre’s activity will enable innovation, business development, and value creation for the Norwegian society. Moreover, in collaboration with universities, the centre will educate the next generation of specialists who will help implement the achieved research results.
              </p>
              <p>
                The DigiWells consortium
                connects the operators, service companies, and academic partners. It is the place where experts of different disciplines talk together.
              </p>
            </div>

            <div className="contact">
              {
                people.management &&
                people.management.map(function(managementGroup, index) {
                  return (
                    <div key={index}>
                      <h2>{managementGroup.groupName}</h2>
                      <div className="people">
                        {
                          managementGroup.people &&
                          managementGroup.people.map(function(person, innerIndex) {
                            const photo = require('../assets/images/' + person.photo);
                            return (
                              <div className="person-card" key={innerIndex}>
                                <img className="person-image" alt="" src={photo}/>
                                <div className="person-text">
                                  <p className="person-name">{person.name}</p>
                                  <p>{person.workTitle}</p>
                                  <p>{person.email}</p>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default AboutPage
